import React from 'react';
import logo from './thijshooft.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to Thijs.digital! This website is under construction :)
        </p>
        <a
          className="App-link"
          href="https://t.me/tijdvoortebot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Use my telegram bot. Or something.
        </a>
      </header>
    </div>
  );
}

export default App;
